import React from "react";
import { number, string } from "prop-types";
import { Link } from "gatsby";
import { useIntl, FormattedMessage } from "react-intl";

const NewsItem = (props) => {
  const intl = useIntl();

  const { date, title, teaser, slug, year } = props;
  return (
    <div className="reveal col-12 col-md-6 mb-3 news-item">
      <div className="bg-monday-green text-color-white h-100 p-3 d-flex flex-column justify-content-between align-items-start">
        <div className="mb-3 w-100">
          <span className="h5">{date}</span>
          <h3 className="h3">{title}</h3>
          <p>{teaser}</p>
        </div>
        <Link
          to={`${intl.formatMessage({ id: "nav.news.link" })}/${year}/${slug}`}
          className="btn"
        >
          <FormattedMessage id="more.text" />
        </Link>
      </div>
    </div>
  );
};

NewsItem.propTypes = {
  date: string.isRequired,
  title: string.isRequired,
  teaser: string.isRequired,
  slug: string.isRequired,
  year: number.isRequired,
};

export default NewsItem;
